'use client';

import { Button } from 'rizzui';
import cn from '../../utils/tailwind/utils';
import { useModal } from './use-modal';
import { dbtime_local_to_dayjs } from '../../utils/dbconvert';
import { PiWarning } from 'react-icons/pi';

interface Props {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  description?: string | React.ReactNode;
  isModal?: boolean

  iconClassName?: string;
  buttonsClassName?: string;
  buttons: Array<{
    color?: 'primary' | 'secondary' | 'danger';
    variant?: 'text' | 'solid' | 'flat' | 'outline';
    text: string;
    className?: string;
    fn?: () => void;
  }>;
}

export function useNotiDialog() {
  const { openModal, closeModal } = useModal();

  const showNotiDialog = ({ title, icon, description, buttons, buttonsClassName,isModal, iconClassName }: Props) => {
    openModal({
      isModal,
      view: (
        <div className='w-full sm:min-w-[420px]  p-8'>
          {icon && <div className={cn('[&>svg]:w-16 [&>svg]:h-16 flex justify-center pb-8', iconClassName)}>{icon}</div>}
          
          {title && <h4 className='text-center'>{title}</h4>}

          {description ?
            (typeof description === 'string' ? <p className='whitespace-pre py-8 leading-loose'>{description}</p> : description)
          : <div className='h-8'/>}

          <div className={cn('flex justify-end border-t border-dashed border-muted-foreground mt-4 pt-4', buttonsClassName)}>
            {buttons.map((item, idx) => (
              <Button
                key={idx}
                color={item.color}
                variant={item.variant}
                className={cn('', item.className)}
                onClick={() => {
                  if (item.fn) item.fn();
                  else closeModal();
                }}
              >
                {item.text}
              </Button>
            ))}
          </div>
        </div>
      ),
    });
  };


  //commmon dialogs
  const expireDateCheck = (items:{expireDate:string, productName:string}[]) =>{
    for (let index =0; index < items.length; index++){
      const item = items[index];

      if(dbtime_local_to_dayjs(item.expireDate).isBefore()){
        showNotiDialog({
          icon: <PiWarning className='text-red-500' />,
          title: (<div>
              유효기간 사용불가
            </div>
          ) ,
          description:(
            <div className='text-center mt-8'>
              <p className='font-semibold text-base text-black'>{item.productName}</p>
              <div className='mt-4' >
                <p>선택하신 제품의 유효기간은</p>
                <p>{dbtime_local_to_dayjs(item.expireDate).format(`YYYY년 MM월 DD일`)}로</p>
                <p> 더 이상 사용할 수 없습니다.</p>
              </div>
            </div>
          ),
          buttons: [{ text: '확인' }],
        });
        return false;
      }
      return true;
    }
  }

  const noInventoryItem = (locationName?:string, standardCode?: string)=>{
    showNotiDialog({
      title: <div>미등록 제품</div>,
      description: `"${locationName ?? '현재위치'}"에 등록되지 않은 재고입니다\n재고 등록 후 사용해 주세요`,
      buttons: [{ text: '확인' }],
    });
  }

  const noRegisteredItem = ()=>{
    showNotiDialog({
      title: <div>미등록 제품</div>,
        description: '선택하신 제품은 등록되지 않은 제품입니다\n제품 등록 후 사용해 주세요',
        buttons: [{ text: '확인' }],
    });
  }

  return {showNotiDialog,closeModal, expireDateCheck, noInventoryItem, noRegisteredItem};
}
