import { atom } from 'jotai';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import { showApiError } from '../../composables/api/api_call';


type AuthTypes = {
  user?: SessionUserData;
  isLogin: boolean
};

const authAtom = atom<AuthTypes>({
  isLogin: false,
});


export class AuthTokenClient {
  static fnRefresh?: (() => Promise<Session|null>) | null;
  static fnSignOut?: (typeof signOut) | null;
  static tkexp?: number;
  static delegateRefresh?: Promise<void> | null //이값이 있으면 refresh 진행중이다.

  static resetComponent(refresh: typeof AuthTokenClient['fnRefresh'], signOut: typeof AuthTokenClient['fnSignOut']) {
    this.fnRefresh = refresh
    this.fnSignOut = signOut
  }

  static async checkRefresh() {
    if (this.delegateRefresh) return this.delegateRefresh;

    this.delegateRefresh = this._checkRefresh();

    //끝나면 delegate 삭제
    const fnFinRefresh = () => {
      this.delegateRefresh = null;
    }
    this.delegateRefresh.then(fnFinRefresh).catch(fnFinRefresh);

    return this.delegateRefresh;
  }

  static async _checkRefresh(): Promise<void> {

    //내 토큰 보자.
    if (!this.tkexp || (this.tkexp) < (Date.now() / 1000)) {
      const session = await this.fnRefresh!();
      if(session){
        this.setLoginData(session);
      }
      else{
        await showApiError('invalid_skey');
      }
    }
  }

  static setLoginData(session?: Session | null) {
    this.tkexp = session?.user?.tkexp;
  }

  static setLogout(){
    this.tkexp = 0;
  }
}

export async function check_token_refresh() {
  await AuthTokenClient.checkRefresh();
}
