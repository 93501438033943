'use client';

import { atom, useAtomValue, useSetAtom } from 'jotai';


type LoadingTypes = {
  isLoading: boolean;
  text?: string;
  delay?: number
};

const loadingAtom = atom<LoadingTypes>({
  isLoading: false,
  text: '',
  delay: undefined
});

const static_client_fn: {
openLoading?:(option: {text?: string;delay?: number})=>void,
closeLoading?:()=>void,
} = {}

export function setGlobalLoadingHook(fn: typeof static_client_fn | null){
  static_client_fn.openLoading = fn?.openLoading;
  static_client_fn.closeLoading = fn?.closeLoading;
}
export function getGlobalLoadingHook(){
  return static_client_fn;
}

export function useGlobalLoading() {
  const state = useAtomValue(loadingAtom);
  const setState = useSetAtom(loadingAtom);

  const openLoading = ({
    text,
    delay,
    
  }:{
    text?: string;
    delay?: number
  }) => {
    setState({
      ...state,
      isLoading: true,
      text,
      delay,
    });
  };

  const closeLoading = () => {
    setState({
      ...state,
      isLoading: false,
    });
  };

  return {
    ...state,
    openLoading,
    closeLoading,
  };
}
