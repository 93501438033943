import Swal from 'sweetalert2';
import { messages } from '@/config/messages';

/*
https://github.com/avil13/vue-sweetalert2?tab=readme-ov-file
https://sweetalert2.github.io/#examples
*/

const popup_class = 'zs-alert-popup';

export async function ShowSystemError(text: string, onAction?: () => void) {
    await ShowAlert(text, { title: 'system' });
    if (onAction) {
        onAction();
    }
}

export async function ShowAlert(text: string, option?: { 
    title?: string, 
    button?: 'ok' | 'reconnect',
    ex_msg?: string //작게 메시지를 추가로 보여주는 것.
    allowOutsideClick?: boolean
}):Promise<any> {
    return new Promise(resolve => {
        const swal = Swal;
        //줄바꿈 변경, whitespace pre로 된다.
        let html = `<div class="zs-alert-conts">${text}</div>`;
        if(option?.ex_msg){
            html += `<div class="zs-alert-conts-exmsg">${option.ex_msg}</div>`;
        }

        if (option?.title) {
            html = `<div class="zs-alert-title">${option.title}</div>` + html;
        }


        const buttonText = messages[option?.button == 'reconnect' ? 'btn.reconnect' : 'btn.ok']

        swal.fire({
            html: html,
            confirmButtonText: buttonText,
            allowOutsideClick: option?.allowOutsideClick ?? false,
            customClass: {
                popup: popup_class,
                confirmButton: `zs-alert-btn-ok px-12`,
                htmlContainer: `zs-alert-html-container`,
                actions: "zs-alert-actions",
            },
            buttonsStyling: false
        }).then(resolve);
    })
}

//yes/no message box
//return promise true/false
export async function ShowYesNoBox(text: string, option?: { 
    title?: string, 
    ex_msg?: string //작게 메시지를 추가로 보여주는 것.
}): Promise<boolean> {

    return new Promise(resolve => {
        const swal = Swal;
        //줄바꿈 변경, whitespace pre로 된다.
        let html = `<div class="zs-alert-conts">${text}</div>`;
        if(option?.ex_msg){
            html += `<div class="zs-alert-conts-exmsg">${option.ex_msg}</div>`;
        }

        if (option?.title) {
            html = `<div class="zs-alert-title">${option.title}</div>` + html;
        }

        swal.fire({
            html: html,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: messages['btn.yes'],
            cancelButtonText: messages['btn.no'],

            customClass: {
                popup: popup_class,
                htmlContainer: `zs-alert-html-container`,
                confirmButton: `zs-alert-btn-yes flex-1`,
                cancelButton: `zs-alert-btn-cancel flex-1`,
                actions: "zs-alert-actions flex-row-reverse",
            },

            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    })
}