'use client';

import getConfig from "next/config";
import { getGlobalLoadingHook, useGlobalLoading } from "../../components/loading-view/use-loading";
import { getMessages } from "../../config/messages";
import { ShowAlert } from "../../components/alerts";
import { AuthTokenClient, check_token_refresh } from "../../components/auth/auth_client";
import { routes } from "../../config/routes";


const isDebugMode = process.env.NEXT_PUBLIC_DEV_MODE == 'true';
let g_showLogout = false;

const PRODUCTION = !isDebugMode;


export interface JSONApiResponseParam<T = any> {
  result: T | null; error: TJsonApiREPacket_Error | null
}
export const api_call = async <T = any>(
  method: string,
  _option: TJsonApiOption<T>
): Promise<JSONApiResponseParam<T>> => {
  const baseURL = "/api";

  //로딩 처리
  let hide_loding: (() => void) | null | undefined;

  //로딩 시작
  function __start_loading() {
    const { openLoading, closeLoading } = getGlobalLoadingHook();

    if(openLoading){
      openLoading({
        delay: _option.loading_delay,
      });
    }
    return closeLoading;
  }

  if (_option.loading !== false) {
    hide_loding = __start_loading();
  }

  if (!PRODUCTION) {
    console.log(`api_call`, "jsonapi send: ", baseURL, {
      method: method,
      body: _option.param,
    });
  }



  try {
    if (!_option.skip_tkrefresh) {
      await check_token_refresh();
    }

    //api call
    const response = await fetch(baseURL, {
      method: "POST",
      body: JSON.stringify({
        method: method,
        param: _option.param
      }),
      credentials: 'include',
    });

    const result:JSONApiResponseParam = await response.json();
    if (!PRODUCTION) {
      console.log(`api_call`, "jsonapi recv: ", result);
    }

    if (typeof result === "string") {
      //에러..
      await _loading_fin(false);
      return {
        error: __on_error({ key: "err_500", message: "result is string" }),
        result: null,
      };
    }
    if (result?.error) {
      //에러..
      await _loading_fin(false);
      return { error: __on_error(result.error), result: null };
    }

    await _loading_fin(true);

    const ret_conv = _option.on_success
      ? await _option.on_success(result as T)
      : undefined;
    return { result: ret_conv ? ret_conv : result, error: null };
  } catch (error: any) {
    let err_msg = "";
    let apiError: TJsonApiREPacket_Error | null = null;

    console.error(error);

    if (error.message) {

    }

    if (!apiError /*|| !apiError?.key*/) {
      apiError = {
        key: "err_500",
        message: error instanceof Error ? error.message : String(error),
      };
    }

    await _loading_fin(false);
    return { error: __on_error(apiError, err_msg ? err_msg : apiError.message), result: null };
  }

  //로딩 종료 확인
  async function _loading_fin(is_success: boolean) {
    //hide loading check
    if (hide_loding) {
      hide_loding();
    }
  }

  //에러 처리
  function __on_error(error: TJsonApiREPacket_Error, err_msg?: string) {
    console.error(`api[${baseURL}]: ${method} error: `, error);
    if (_option.on_error === false) return error;

    //로그아웃된것은 바로 처리 해버리자.
    if(error.key == 'invalid_skey'){
      showApiError(error.key);
      return error;
    }

    if (!_option.on_error || !(_option.on_error(error))) {
      //alert보여주자.
      showApiError(error.key, err_msg ? err_msg : error.message);
    }
    return error;
  }
};

export function getApiError(err_key: TJsonApiError, err_msg?: string){
  let msg: string;
  if (err_key == "invalid_skey") {
    msg = getMessages("api.skey");
  } else if (err_key == "key_err") {
    //데이터 에러로 보여주자. 핵심 키가 없을때 에러임.
    msg = getMessages("api.err");
  } else if (err_key == "err_connect") {
    msg = getMessages("api.net");
  } else if (err_key == "err_500") {
    msg = getMessages("api.er500");
  } else if (err_key == "invalid_param") {
    msg = getMessages("api.err");
  }  else if (err_key == "no_data") {
    msg = getMessages("api.no_data");
  } else {
    msg = getMessages("api.err");
  }

  if (err_msg && err_key != err_msg) msg += "\n" + err_msg;
  //에러 key도 보여주자.
  msg += `\n[${err_key}]`;
  return msg;
}

// 기본 에러 보여주기.
export async function showApiError(err_key: TJsonApiError, err_msg?: string, noDefaultAction?: boolean) {
  let msg: string, fn_act: "reload" | "home" | 'logout' | undefined;

  // if(noDefaultAction === undefined) noDefaultAction = !PRODUCTION;

  if (err_key == "invalid_skey") {
    if(g_showLogout) return;
    
    g_showLogout = true;
    msg = getMessages("api.skey");
    fn_act = "logout";
    AuthTokenClient.setLogout();
    await ShowAlert(msg!, { title: "system" });
    window.location.href = routes.signIn;
    return;
  } else if (err_key == "key_err") {
    //데이터 에러로 보여주자. 핵심 키가 없을때 에러임.
    msg = getMessages("api.err");
    fn_act = "home";
  } else if (err_key == "err_connect") {
    msg = getMessages("api.net");
    fn_act = "reload";
    await ShowAlert(msg!, { title: "system" });
    return;
  } else if (err_key == "err_500") {
    msg = getMessages("api.er500");
    fn_act = "home";
  } else if (err_key == "invalid_param") {
    msg = getMessages("api.err");
    fn_act = "reload";
  } else if (err_key == "no_data") {
    msg = getMessages("api.no_data");
  } else {
    msg = getMessages("api.err");
    fn_act = "home";
  }

  if (err_msg && err_key != err_msg) msg += "\n\n" + err_msg;
  //에러 key도 보여주자.
  msg += `\n[${err_key}]`;

  await ShowAlert(msg!, { title: "system" });

  // if (!PRODUCTION && fn_act) {
  //   if (!confirm(`Api Error Action 실행??\n\n${fn_act}`)) return;
  // }

  // if (fn_act == "reload") {
  //   window.location.reload();
  // } else if (fn_act == "home") {
  //   window.location.href = "/";
  // } else if (fn_act == "logout") {
  //   AuthTokenClient.setLogout();
  //   window.location.reload();
  // }
}
